var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isVisible)?_c('div',{staticClass:"sidebar-container",style:({ overflow: _vm.estagioDeOverflowVisible ? 'visible' : 'scroll' })},[_c('div',{staticClass:"sidebar-header"},[_c('h4',{staticClass:"sidebar-title",domProps:{"textContent":_vm._s(_vm.activeNode.label)}}),_c('button',{staticClass:"tooltip-button",on:{"click":_vm.abrirModalInformacoesEstagio}},[_c('i',{staticClass:"fas fa-info"})])]),_c('div',{staticClass:"fluxo-inputs-container"},[_c('p',{staticClass:"text-title",staticStyle:{"margin":"2px"}},[_vm._v(" Quantidade "),_c('b',[_vm._v("máxima")]),_vm._v(" de filhos: "),_c('strong',[_vm._v(_vm._s(_vm.activeNode.ces_metadata.qtd_max_filho))])]),_c('p',{staticClass:"text-title",staticStyle:{"margin":"2px"}},[_vm._v(" Quantidade "),_c('b',[_vm._v("mínima")]),_vm._v(" de filhos: "),_c('strong',[_vm._v(_vm._s(_vm.activeNode.ces_metadata.qtd_min_filho))])]),_c('hr'),(_vm.activeNode.chatbot_estagio_id == 10)?_c('div',{staticClass:"tomar-decisao-container"},_vm._l((_vm.activeNode.ces_metadata.campos),function(child,childIndex){return _c('div',{key:'child-' + childIndex,staticClass:"tomar-decisao-item"},[(_vm.childrens[childIndex])?[(_vm.childrens[childIndex])?_c('h6',{domProps:{"textContent":_vm._s(("" + (_vm.childrens[childIndex].label)))}}):_vm._e(),_vm._l((_vm.activeNode.ces_metadata.campos[childIndex]),function(campo,key){return _c('div',{key:'campo-' + childIndex + '-' + key,staticClass:"mt-2"},[(
                !_vm.modoAvancadoTomarDecisao(
                  _vm.activeNode.ces_metadata.campos[childIndex]
                )
              )?[(
                  ![
                    'cec_ordem',
                    'cec_condicao',
                    'para_chatbot_fluxo_estagio_id'
                  ].includes(campo.bindText) && campo.type !== 'bool'
                )?_c('p',{staticClass:"text-title",domProps:{"textContent":_vm._s(campo.label)}}):_vm._e(),(
                  campo.type === 'text' &&
                  ![
                    'cec_ordem',
                    'cec_condicao',
                    'para_chatbot_fluxo_estagio_id'
                  ].includes(campo.bindText) &&
                  !_vm.modoAvancadoTomarDecisao(
                    _vm.activeNode.ces_metadata.campos[childIndex]
                  )
                )?_c('b-form-input',{attrs:{"type":"text"},model:{value:(campo.value),callback:function ($$v) {_vm.$set(campo, "value", $$v)},expression:"campo.value"}}):_vm._e(),(
                  campo.type === 'select' &&
                  ![
                    'cec_ordem',
                    'cec_condicao',
                    'para_chatbot_fluxo_estagio_id'
                  ].includes(campo.bindText)
                )?_c('b-form-select',{staticClass:"w-100",attrs:{"name":campo.bindText,"options":_vm.optionsDoCampo(campo.bindText)},model:{value:(campo.value),callback:function ($$v) {_vm.$set(campo, "value", $$v)},expression:"campo.value"}}):_vm._e()]:_vm._e(),(
                campo.bindText == 'cec_condicao' &&
                _vm.modoAvancadoTomarDecisao(
                  _vm.activeNode.ces_metadata.campos[childIndex]
                )
              )?_c('b-form-textarea',{attrs:{"rows":3,"type":"text"},model:{value:(campo.value),callback:function ($$v) {_vm.$set(campo, "value", $$v)},expression:"campo.value"}}):_vm._e(),(
                campo.type === 'bool' &&
                ![
                  'cec_ordem',
                  'cec_condicao',
                  'para_chatbot_fluxo_estagio_id'
                ].includes(campo.bindText)
              )?_c('ToggleButton',{staticClass:"w-100 text-uppercase",attrs:{"onLabel":campo.onLabel || 'Modo Avançado',"offLabel":campo.offLabel},model:{value:(campo.value),callback:function ($$v) {_vm.$set(campo, "value", $$v)},expression:"campo.value"}}):_vm._e()],2)}),_c('hr')]:_vm._e()],2)}),0):_c('div',[_vm._l((_vm.computedCampos),function(campo,key){return _c('div',{key:'campo' + key,staticClass:"mt-3"},[(
            !(campo.bindText == 'cfe_time' && _vm.encaminharParaAgendaWellonChat)
          )?[_c('div',{staticClass:"title-container"},[(![25].includes(_vm.activeNode.chatbot_estagio_id))?_c('p',{staticClass:"text-title",staticStyle:{"margin":"2px"}},[_vm._v(" "+_vm._s(campo.label)+" ")]):_vm._e(),(_vm.campoDeVariavel(campo))?_c('div',{staticClass:"tooltip-container"},[(_vm.campoDeVariavel(campo))?_c('i',{staticClass:"fa fa-question-circle"}):_vm._e(),_vm._m(0,true)]):_vm._e()]),(campo.type === 'text')?_c('b-form-input',{attrs:{"type":"text"},model:{value:(campo.value),callback:function ($$v) {_vm.$set(campo, "value", $$v)},expression:"campo.value"}}):(campo.type === 'textArea')?_c('b-form-textarea',{attrs:{"rows":5},model:{value:(campo.value),callback:function ($$v) {_vm.$set(campo, "value", $$v)},expression:"campo.value"}}):_vm._e(),_c('div',{staticClass:"d-flex align-items-center"},[(campo.type === 'select')?_c('b-form-select',{staticClass:"w-100",attrs:{"name":campo.bindText,"options":_vm.optionsDoCampo(campo.bindText)},on:{"change":function($event){return _vm.onChangeMainChatbotSelect(campo, $event)}},model:{value:(campo.value),callback:function ($$v) {_vm.$set(campo, "value", $$v)},expression:"campo.value"}}):_vm._e(),(
                _vm.activeNode.ces_estagio == 'ESTAGIO_REDIRECIONAR_FLUXO' &&
                campo.value &&
                campo.bindText == 'cfe_atributo'
              )?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fas fa-external-link-alt ml-2 d-flex align-items-center justify-content-center",staticStyle:{"font-size":"18px","cursor":"pointer","border":"1px solid #e4e6ef","padding":"4px","border-radius":"4px","width":"38px","height":"38px"},attrs:{"title":"Mostrar fluxo em outra janela"},on:{"click":function($event){return _vm.abrirFluxoEmNovaAba(campo.value)}}}):_vm._e()],1),(
              campo.type === 'multiSelect' &&
              _vm.activeNode.chatbot_estagio_id == 17
            )?_c('MultiSelect',{staticClass:"col w-100",attrs:{"options":_vm.agendasMultiSelect,"optionLabel":"text","placeholder":"Parametros","filter":true,"size":"lg"},model:{value:(campo.value),callback:function ($$v) {_vm.$set(campo, "value", $$v)},expression:"campo.value"}}):_vm._e(),(
              campo.type === 'multiSelect' &&
              _vm.activeNode.chatbot_estagio_id != 17
            )?_c('MultiSelect',{staticClass:"col w-100",attrs:{"options":_vm.variaveisDeclaradas,"optionLabel":"text","placeholder":"Parametros","filter":true,"size":"lg"},model:{value:(campo.value),callback:function ($$v) {_vm.$set(campo, "value", $$v)},expression:"campo.value"}}):_vm._e(),(campo.type === 'file')?_c('b-form-file',{attrs:{"state":Boolean(campo.value),"placeholder":campo.value ? campo.value : 'Selecione um arquivo',"accept":"*","browse-text":"Selecionar"},on:{"change":function($event){return _vm.onChangeFile(campo, $event)}}},[_vm._v(" >")]):_vm._e(),(campo.type === 'file')?_c('b-button',{staticClass:"mt-2 w-100",attrs:{"variant":"outline-primary"},on:{"click":_vm.showFilePreview}},[_vm._v(" "+_vm._s(_vm.arquivoASerEnviado ? "Visualizar arquivo" : "Nenhum arquivo selecionado")+" ")]):_vm._e(),(campo.type === 'bool')?_c('ToggleButton',{staticClass:"w-100 text-uppercase",attrs:{"onLabel":campo.onLabel,"offLabel":campo.offLabel},model:{value:(campo.value),callback:function ($$v) {_vm.$set(campo, "value", $$v)},expression:"campo.value"}}):_vm._e()]:_vm._e()],2)}),(_vm.activeNode.chatbot_estagio_id == 25)?[_c('ul',[_vm._l((_vm.activeNode.ces_metadata.campos.find(
              function (campo) { return campo.bindText == 'atributos_customizaveis'; }
            ).value),function(atributo,idx){return _c('li',{key:'atributo-' + idx,staticStyle:{"border":"1px solid #ccc","margin-top":"8px","padding":"8px","border-radius":"8px","list-style":"none"}},[_vm._m(1,true),_c('b-form-select',{staticClass:"w-100",attrs:{"name":'selecionado-' + idx + atributo.atributo_customizavel,"options":_vm.atributoCustomizavelOptions},on:{"change":function($event){return _vm.onChangeAtributoSelecionado(atributo, $event)}},model:{value:(atributo.atributo_customizavel),callback:function ($$v) {_vm.$set(atributo, "atributo_customizavel", $$v)},expression:"atributo.atributo_customizavel"}}),_vm._m(2,true),(atributo.tipo === 'text')?_c('b-form-input',{attrs:{"type":"text"},model:{value:(atributo.valor),callback:function ($$v) {_vm.$set(atributo, "valor", $$v)},expression:"atributo.valor"}}):_vm._e(),(atributo.tipo === 'select')?_c('b-form-select',{staticClass:"w-100",attrs:{"name":'valor-' + idx + atributo.atributo_customizavel,"options":_vm.optionsAtributoCustomizavelSelecionado(atributo)},model:{value:(atributo.valor),callback:function ($$v) {_vm.$set(atributo, "valor", $$v)},expression:"atributo.valor"}}):_vm._e(),_c('b-button',{staticClass:"mt-2 w-100",attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.activeNode.ces_metadata.campos
                  .find(
                    function (campo) { return campo.bindText == 'atributos_customizaveis'; }
                  )
                  .value.splice(idx, 1)}}},[_c('i',{staticClass:"fas fa-trash"}),_vm._v(" Remover ")])],1)}),_c('b-button',{staticClass:"mt-4 w-100",staticStyle:{"box-shadow":"rgba(100, 100, 111, 0.5) 0px 7px 29px 0px"},attrs:{"variant":"success"},on:{"click":function($event){_vm.activeNode.ces_metadata.campos
                .find(function (campo) { return campo.bindText == 'atributos_customizaveis'; })
                .value.push({
                  atributo_customizavel: '',
                  valor: '',
                  tipo: ''
                })}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Novo Atributo ")])],2)]:_vm._e(),(_vm.activeNode.chatbot_estagio_id == 21)?[(
            _vm.activeNode.ces_metadata.campos.find(
              function (campo) { return campo.bindText == 'menu_tp'; }
            ).value == 'E'
          )?_c('draggable',_vm._b({staticClass:"list-group",attrs:{"tag":"ul"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(
            _vm.activeNode.ces_metadata.campos.find(
              function (campo) { return campo.bindText == 'menu_conteudo'; }
            ).value
          ),callback:function ($$v) {_vm.$set(_vm.activeNode.ces_metadata.campos.find(
              function (campo) { return campo.bindText == 'menu_conteudo'; }
            ), "value", $$v)},expression:"\n            activeNode.ces_metadata.campos.find(\n              (campo) => campo.bindText == 'menu_conteudo'\n            ).value\n          "}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.activeNode.ces_metadata.campos.find(
                function (campo) { return campo.bindText == 'menu_conteudo'; }
              ).value),function(submenu,idx){return _c('li',{key:'submenu-' + idx,staticClass:"list-group-item",staticStyle:{"border":"1px solid #ccc","border-radius":"8px","margin-top":"8px","padding":"8px"}},[_c('div',{staticClass:"title-container"},[_c('p',{staticClass:"text-title",staticStyle:{"margin":"2px"}},[_vm._v("Texto:")])]),_c('b-form-textarea',{attrs:{"rows":3},model:{value:(submenu.texto),callback:function ($$v) {_vm.$set(submenu, "texto", $$v)},expression:"submenu.texto"}}),_c('div',{staticClass:"title-container"},[_c('p',{staticClass:"text-title",staticStyle:{"margin":"2px"}},[_vm._v("Valor:")])]),_c('b-form-input',{attrs:{"type":"text"},model:{value:(submenu.valor),callback:function ($$v) {_vm.$set(submenu, "valor", $$v)},expression:"submenu.valor"}}),_c('div',{staticClass:"title-container"},[_c('p',{staticClass:"text-title",staticStyle:{"margin":"2px"}},[_vm._v("Palavras Chave:")])]),_c('Chips',{staticClass:"w-100",attrs:{"separator":","},scopedSlots:_vm._u([{key:"chip",fn:function(slotProps){return [_c('div',[_c('span',[_vm._v(_vm._s(slotProps.value))])])]}}],null,true),model:{value:(submenu.palavras_chave),callback:function ($$v) {_vm.$set(submenu, "palavras_chave", $$v)},expression:"submenu.palavras_chave"}}),_c('b-button',{staticClass:"mt-2 w-100",attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.activeNode.ces_metadata.campos
                    .find(function (campo) { return campo.bindText == 'menu_conteudo'; })
                    .value.splice(idx, 1)}}},[_c('i',{staticClass:"fas fa-trash"}),_vm._v(" Remover ")])],1)}),0)],1):_vm._e(),(
            _vm.activeNode.chatbot_estagio_id == 21 &&
            _vm.activeNode.ces_metadata.campos.find(
              function (campo) { return campo.bindText == 'menu_tp'; }
            ).value == 'D'
          )?[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.activeNode.ces_metadata.campos.find(
                function (campo) { return campo.bindText == 'menu_conteudo'; }
              ).value),function(submenu,idx){return _c('li',{key:'submenu-' + idx,staticClass:"list-group-item",staticStyle:{"border":"1px solid #ccc","border-radius":"8px","margin-top":"8px","padding":"8px"}},[_c('div',{staticClass:"title-container"},[_c('p',{staticClass:"text-title",staticStyle:{"margin":"2px"}},[_vm._v("Texto:")])]),_c('b-form-textarea',{attrs:{"rows":3},model:{value:(submenu.texto),callback:function ($$v) {_vm.$set(submenu, "texto", $$v)},expression:"submenu.texto"}}),_c('div',{staticClass:"title-container"},[_c('p',{staticClass:"text-title",staticStyle:{"margin":"2px"}},[_vm._v("Variável:")])]),_c('b-form-input',{attrs:{"type":"text"},model:{value:(submenu.variavel),callback:function ($$v) {_vm.$set(submenu, "variavel", $$v)},expression:"submenu.variavel"}}),_c('div',{staticClass:"title-container"},[_c('p',{staticClass:"text-title",staticStyle:{"margin":"2px"}},[_vm._v("Coluna:")])]),_c('b-form-input',{attrs:{"type":"text"},model:{value:(submenu.coluna),callback:function ($$v) {_vm.$set(submenu, "coluna", $$v)},expression:"submenu.coluna"}})],1)}),0)]:_vm._e(),(
            _vm.activeNode.ces_metadata.campos.find(
              function (campo) { return campo.bindText == 'menu_tp'; }
            ).value == 'E'
          )?_c('b-button',{staticClass:"mt-4 w-100",staticStyle:{"box-shadow":"rgba(100, 100, 111, 0.5) 0px 7px 29px 0px"},attrs:{"variant":"success"},on:{"click":_vm.adicionarNovoItemDeSubmenu}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Novo Menu ")]):_vm._e()]:_vm._e()],2)])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tooltip-mensagem"},[_c('p',{staticClass:"text-title",staticStyle:{"margin":"2px"}},[_vm._v(" O parâmetro será responsável por armazenar a mensagem ou arquivo que será enviado pelo usuário. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-container"},[_c('p',{staticClass:"text-title",staticStyle:{"margin":"2px"}},[_vm._v("Atributo")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-container mt-3"},[_c('p',{staticClass:"text-title",staticStyle:{"margin":"1px"}},[_vm._v("Valor")])])}]

export { render, staticRenderFns }